import React, { useState, useEffect } from "react";
import NavbarRedirect from "../navBarRedirect/NavbarRedirect";
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableContainer,
  Heading,
  useColorModeValue,
  Tfoot,
  Button,
  Flex,
  Input,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import Swal from "sweetalert2";

import { onAuthStateChanged, getAuth, updateProfile } from "firebase/auth";
import {
  doc,
  setDoc,
  collection,
  addDoc,
  getDocs,
  onSnapshot,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";

import { storage } from "../../firebase";
import { v4 } from "uuid";
const Perfil = () => {
  const auth = getAuth();

  const [userData, setUserData] = useState();
  const [userToShow, setUserToShow] = useState();

  const loadUserData = () => {};

  const fetchDataUser = async (user) => {
    try {
      // const userId = auth.currentUser.uid;
      const docRef = doc(db, "user", user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserToShow(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
      }
    } catch (error) {
      console.log("no labura");
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        setUserData(user);
        console.log(userData);
        fetchDataUser(user);
      } else {
        // User is signed out
        // ...
      }
    });
  }, []);
  const [editActive, setEditActive] = useState(false);
  const editView = () => {
    setEditActive(!editActive);
  };

  const [formData, setFormData] = useState({
    displayName: userToShow?.displayName,
    phoneNumber: userToShow?.phoneNumber,
    photoURL: "",
  });
  const { displayName, phoneNumber, photoURL } = formData;

  const handleChange = (text) => (e) => {
    if (text != "") {
    }
    console.log({ ...formData, [text]: e.target.value });
    console.log(e);

    setFormData({ ...formData, [text]: e.target.value });
    // console.log(formData);
  };
  const editProfile = async () => {
    if (
      displayNameValidate == false &&
      displayName != null &&
      displayName != "" &&
      // lastNameValidate == false &&
      // lastName != null &&
      // lastName != "" &&
      phoneNumberValidate == false &&
      phoneNumber != "" &&
      phoneNumber != null
      // &&
      // passwordValidate == false &&
      // password != "" &&
      // password != null
    ) {
      const auth = getAuth();
      await updateProfile(auth.currentUser, {
        displayName: displayName,
      })
        .then(() => {
          loadUserData();
          setFormData({
            displayName: "",
            phoneNumber: "",
            photoURL: "",
          });
          editView();
        })
        .catch((error) => {
          console.log("errorerror", error);
        });
      const docRef = doc(db, "user", userData?.uid);
      await updateDoc(docRef, {
        displayName: displayName,
        phoneNumber: phoneNumber,
      });
      setUserToShow({
        displayName: displayName,
        phoneNumber: phoneNumber,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Debes ingresar todos los datos!",
      });
    }
  };

  // validaciones
  const [displayNameValidate, setDisplayNameValidate] = useState(false);
  const [phoneNumberValidate, setPhoneNumberValidate] = useState(false);
  //  const [lastNameValidate, setlastNameValidate] = useState(false);

  const requeridoDisplayName = (e) => {
    let expresion = /^[A-Za-z0-9\s]+$/g;

    if (
      // displayName.length > 2 &&
      displayName != null &&
      displayName != "" &&
      displayName.trim() != null &&
      expresion.test(displayName)
    ) {
      setDisplayNameValidate(false);
    } else {
      setDisplayNameValidate(true);
    }
  };

  const requeridoPhoneNumber = (e) => {
    let expresion = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    if (
      // phoneNumber.length > 7 &&
      phoneNumber != null &&
      phoneNumber != "" &&
      phoneNumber.trim() != null &&
      expresion.test(phoneNumber)
    ) {
      setPhoneNumberValidate(false);
    } else {
      setPhoneNumberValidate(true);
    }
  };

  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const imagesListRef = ref(storage, "images/");
  const uploadFile = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
      });
    });
  };

  useEffect(() => {
    listAll(imagesListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageUrls((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  return (
    <div>
      <NavbarRedirect></NavbarRedirect>
      <Box w="100%" h="4em"></Box>
      <Box
        bg={useColorModeValue("brand.lightBlue5", "brand.sodarkblue2")}
        align={"center"}
        justify={"center"}
        h="100vh"
        p={5}
      >
        <Heading size="3xl">Bienvenido {userData?.displayName}</Heading>
        <TableContainer
          boxShadow="dark-lg"
          bgGradient={useColorModeValue(
            "linear(to-t, brand.green6, brand.lightBlue5)",
            "linear(to-t, brand.darkblue3, brand.blue4)"
          )}
          borderRadius="12"
          maxW={{
            base: "95%", // 0-48em
            md: "70%", // 48em-80em,
            xl: "50%", // 80em+
          }}
          my="2em"
          variant="simple"
        >
          <Table size="sm" className="table-tiny">
            <Thead>
              <Tr>
                {editActive == false ? (
                  <Flex align={"center"} justify={"space-between"} p={3}>
                    <Heading size="2xl">Datos</Heading>

                    <Button
                      onClick={editView}
                      colorScheme="blue"
                      bgGradient="linear(to-b, brand.lightBlue5, brand.blue4, brand.darkblue3)"
                      color="black"
                      isFullWidth
                    >
                      Editar
                    </Button>
                  </Flex>
                ) : (
                  <Flex align={"center"} justify={"space-between"} p={3}>
                    <Heading size="2xl">Datos</Heading>
                    <Button
                      onClick={editView}
                      colorScheme="blue"
                      bgGradient="linear(to-b, brand.lightBlue5, brand.blue4, brand.darkblue3)"
                      color="black"
                      isFullWidth
                    >
                      Volver
                    </Button>
                    <Button
                      onClick={editProfile}
                      colorScheme="blue"
                      bgGradient="linear(to-b, brand.lightBlue5, brand.blue4, brand.darkblue3)"
                      color="black"
                      isFullWidth
                    >
                      Aceptar
                    </Button>
                  </Flex>
                )}
              </Tr>
            </Thead>
            {userToShow ? (
              <Tbody>
                {editActive == false ? (
                  <Box>
                    <Box p={4}>
                      {" "}
                      Nombre y Apellido : {userToShow?.displayName}
                    </Box>
                    <Box p={4}> phoneNumber : {userToShow?.phoneNumber}</Box>
                    {/* <Box p={4}> Foto - Avatar : {userToShow?.photoURL}</Box> */}
                  </Box>
                ) : (
                  <Box>
                    <Box p={4}>
                      {" "}
                      Nombre y Apellido :{" "}
                      <FormControl
                        id="displayNameValidate"
                        isInvalid={displayNameValidate}
                      >
                        <Input
                          border="2px solid "
                          borderColor="brand.darkblue3"
                          placeholder={userToShow?.displayName}
                          onChange={handleChange("displayName")}
                          value={displayName}
                          onBlur={requeridoDisplayName}
                        />
                        {!displayNameValidate ? (
                          <div>
                            <br></br>
                          </div>
                        ) : (
                          <FormErrorMessage>
                            Ingrese su nombre y apellido.
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                    <Box p={4}>
                      {" "}
                      N° Teléfono :{" "}
                      <FormControl
                        id="phoneNumberValidate"
                        isInvalid={phoneNumberValidate}
                      >
                        <Input
                          border="2px solid "
                          borderColor="brand.darkblue3"
                          placeholder={userToShow?.phoneNumber}
                          onChange={handleChange("phoneNumber")}
                          value={phoneNumber}
                          onBlur={requeridoPhoneNumber}
                        />{" "}
                        {!phoneNumberValidate ? (
                          <div>
                            <br></br>
                          </div>
                        ) : (
                          <FormErrorMessage>
                            Ingrese su N° de teléfono.
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                    {/* <Box p={4}>
                      {" "}
                      Foto - Avatar :{" "}
                      <Input
                        variant="filled"
                        placeholder={userToShow?.photoURL}
                        onChange={handleChange("photoURL")}
                        value={photoURL}
                      />
                    </Box> */}
                  </Box>
                )}
              </Tbody>
            ) : (
              <Box>waiting</Box>
            )}
          </Table>
        </TableContainer>

        {/* <div>
          <input
            type="file"
            onChange={(event) => {
              setImageUpload(event.target.files[0]);
            }}
          />
          <button onClick={uploadFile}> Upload Image</button>
          {imageUrls.map((url) => {
            return <img src={url} />;
          })}
        </div> */}
      </Box>
    </div>
  );
};

export default Perfil;
