import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Card,
  Button,
  CardHeader,
  Heading,
  FormControl,
  Input,
  Badge,
  Select,
  Box,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
} from "@chakra-ui/react";
import { BsThreeDotsVertical, BsChatSquareQuote } from "react-icons/bs";
import { RiShutDownLine, RiRestartLine, RiFileShredLine } from "react-icons/ri";
import { FiEdit2, FiTrash2, FiPlus } from "react-icons/fi";
import { BiLinkAlt } from "react-icons/bi";


import Swal from "sweetalert2";

import {
  doc,
  setDoc,
  collection,
  addDoc,
  getDocs,
  onSnapshot,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";

const Ligas = () => {
  // modal logic New Event
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  // carga evento logic

  const [formData, setFormData] = useState({
    title: "",
    iframeURL: "",
  });
  const { title, iframeURL} = formData;

  const handleChange = (text) => (e) => {
    setFormData({ ...formData, [text]: e.target.value });
    console.log("formdata0", formData);
  };

  // Edit evento logic

  const [ligas, setLigas] = useState([]);

  const [titleValidate, setTitleValidate] = useState(false);
  
  // Inserta evento logic

  const handleSubmit = (e) => {
    e.preventDefault();
     {
      var data = {
        title,
        iframeURL,
      };
      addDoc(collection(db, "ligas"), data);
      fetchData();
      setFormData({
        title: "",
        iframeURL: "",
      });
      Swal.fire("Liga creada", "", "success");
      handleClose();
    }
  };
  const requeridoTitle = (e) => {
    let expresion = /^[A-Za-z0-9\s]+$/g;

    if (
      title.length > 2 &&
      title != null &&
      title != "" &&
      // title.trim() != null &&
      expresion.test(title)
    ) {
      setTitleValidate(false);
    } else {
      setTitleValidate(true);
    }
  };
  
  

  var arrayLigas = [];
  var fetchData = async () => {
    const eventosLista = await getDocs(collection(db, "ligas"));

    eventosLista.forEach((doc) => {
      var objectData = doc.data();
      objectData.uid = doc.id;

      arrayLigas.push(objectData);
    });
    setLigas(arrayLigas);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteLiga = async (idToDelete) => {
    await deleteDoc(doc(db, "ligas", idToDelete));

    fetchData();
    Swal.fire("Evento Eliminado", "", "success");
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    console.log("EAAAA", idToEdit);
    const data = {
      title: titleToEdit,
      iframeURL: iframeURLToEdit,
    };
    console.log("idToEdit", idToEdit);
    console.log("data", data);
    await setDoc(doc(db, "ligas", idToEdit), data);

    fetchData();

    setIsOpenEdit(false);
    Swal.fire("Evento modificado", "", "success");
    setFormDataEdit({
      idToEdit: "",
      titleToEdit: "",
      iframeURLToEdit: "",
    });
  };

  // modal logic edit Event
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const handleOpenEdit = async (idRecordToEdit) => {
    // const docRef = await doc(db, "eventos", idRecordToEdit);

    console.log("idRecordToEdit 11", idRecordToEdit);
    console.log("formDataEdit 11", formDataEdit);
    const docRef = await doc(db, "ligas", idRecordToEdit);
    console.log("docRef", docRef);
    const docSnap = await getDoc(docRef);
    console.log("docSnap", docSnap);

    if (docSnap.exists()) {
      var titleToEditBox = docSnap.data().title;
      var iframeURLToEditBox = docSnap.data().iframeURL;
    }
    setFormDataEdit({
      ...formDataEdit,
      idToEdit: idRecordToEdit,
      titleToEdit: titleToEditBox,
      iframeURLToEdit: iframeURLToEditBox,
    });
    // ({ idToEdit: idRecordToEdit });
    console.log("formDataEdit22 ", formDataEdit);
    // const docSnap = await getDoc(docRef);

    setIsOpenEdit(true);
  };
  const handleCloseEdit = () => setIsOpenEdit(false);

  const [formDataEdit, setFormDataEdit] = useState({
    idToEdit: "",
    titleToEdit: "",
    iframeURLToEdit: "",
  });

  const { titleToEdit, iframeURLToEdit, idToEdit } =
    formDataEdit;
  const handleChangeEdit = (text) => (e) => {
    setFormDataEdit({ ...formDataEdit, [text]: e.target.value });
    console.log("edit form  ", formDataEdit);
  };

  return (
    <>
      <Card m={3}>
        <CardHeader bg="#1FB59A">
          <Heading align="center" size="lg" color='white' >
            {" "}
            Tabla de Ligas
          </Heading>
        </CardHeader>
      </Card>
      <Box textAlign="right">
        <Button
          onClick={handleOpen}
          textAlign="right"
          colorScheme="blue"
          variant="outline"
          size="lg"
          m={2}
        >
          <FiPlus></FiPlus>‎ Nueva Liga
        </Button>
      </Box>

      <Card>
        <TableContainer>
          <Table size="lg">
            <Thead bg="#1FB59A" >
              <Tr>
                <Th textAlign="center" color='white'>Nombre de la Liga</Th>
                <Th textAlign="center" color='white'>Iframe URL</Th>
                <Th textAlign="center" color='white'>Acciónes</Th>
              </Tr>
            </Thead>
            <Tbody>
              {ligas &&
                ligas?.map((event) => (
                  <Tr key={event?.uid}>
                    <Td>{event?.title}</Td>

                    <Td textAlign="center">
                      {event?.iframeURL}</Td>
                    <Td textAlign="center">
                      <Button
                        colorScheme="blue"
                        size="sm"
                        m={2}
                        onClick={() => handleOpenEdit(event?.uid)}
                      >
                        <FiEdit2></FiEdit2>
                      </Button>
                      <Button
                        colorScheme="red"
                        size="sm"
                        m={2}
                        onClick={() => handleDeleteLiga(event?.uid)}
                      >
                        <FiTrash2></FiTrash2>
                      </Button>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Card>
      <Modal size="xl" isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit}>
            <ModalHeader textAlign="center">Nuevo Evento</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl mt={4} id="title" isInvalid={titleValidate}>
                <FormLabel>Título</FormLabel>
                <Input
                  type="text"
                  onChange={handleChange("title")}
                  value={title}
                  onBlur={requeridoTitle}
                  variant="flushed"
                  placeholder="Ej: Noticias"
                />
                {!titleValidate ? (
                  <div></div>
                ) : (
                  <FormErrorMessage>
                    Ingrese un título por favor.
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Iframe URL Liga</FormLabel>
                <Input variant="flushed" onChange={handleChange("iframeURL")} value={iframeURL}></Input>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                Guardar
              </Button>
              <Button onClick={handleClose}>Cancelar</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Modal size="xl" isOpen={isOpenEdit} onClose={handleCloseEdit}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmitEdit}>
            <ModalHeader textAlign="center">Editar Liga</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl mt={4} id="titleToEdit">
                <FormLabel>Título</FormLabel>
                <Input
                  type="text"
                  onChange={handleChangeEdit("titleToEdit")}
                  value={titleToEdit}
                  onBlur={requeridoTitle}
                  variant="flushed"
                  placeholder={titleToEdit}
                />
              </FormControl>
              <FormControl mt={4} id="iframeURLToEdit">
                <FormLabel>Iframe URL</FormLabel>
                <Input
                  type="text"
                  onChange={handleChangeEdit("iframeURLToEdit")}
                  value={iframeURLToEdit}
                  variant="flushed"
                  placeholder={iframeURLToEdit}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                Guardar
              </Button>
              <Button onClick={handleCloseEdit}>Cancelar</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Ligas;
