import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "../feauteres/AnimationRevealPage.js";
//import Hero from "../feauteres/BackgroundAsImage.js";
//import Hero from "../feauteres/TwoColumnWithFeaturesAndTestimonial.js";
import Servicios from "../feauteres/ThreeColWithSideImage.js";
import Nosotros from "../feauteres/Nosotros.js";
import Redes from "../feauteres/RedesSociales.js";
import Testimonios from "../feauteres/Testimonios.js";
import Banner from "../feauteres/Banner.js";
import Footer from "../feauteres/Footer.js";
import Contacto from "../feauteres/SimpleContactUs.js"


export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-logo-100`;
  const HighlightedText = tw.span`text-logo-300 `;

  return (
    <AnimationRevealPage>
      {/* <Hero roundedHeaderButton={true} /> */}
            <Nosotros 
              subheading={<Subheading>NOSOTROS</Subheading>}
            />
        
        <Testimonios/>
      <Servicios
        subheading=""
        heading={
          <>
            Nuestros <HighlightedText style={{color: "#1FB59A"}}>Servicios.</HighlightedText>
          </>
        }
      />
      <Banner/>
      <Redes/>
      <Contacto/>
      <Footer />
    </AnimationRevealPage>
  );
}
