import {
    Flex,
    Button,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    useColorModeValue,
    Stack,
    Box,
    MenuList,
    MenuItem,
    MenuButton,
    Center,
    useColorMode,
    MenuDivider,
    Menu,
    Avatar,
    IconButton,
    useMediaQuery,
    useDisclosure,
    HStack
  } from "@chakra-ui/react";
  import { MoonIcon, SunIcon, CloseIcon , HamburgerIcon } from "@chakra-ui/icons";
  import {useEffect, ReactNode, useState } from "react";
  import logo from "../../assets/logo.png";
  import { BiUser,  BiListUl } from "react-icons/bi";
  import { useNavigate } from "react-router-dom";
  import { Link } from 'react-router-dom';
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import { signOut, onAuthStateChanged } from "firebase/auth";
import Swal from "sweetalert2";
  
  
  export default function Nav({ color }) {

    const [dataUser, setdataUser] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();

  const currentUserlocalStorage = JSON.parse(
    localStorage.getItem("currentUser")
  );

  const signOutAccount = () => {
    signOut(auth)
      .then(() => {
        Swal.fire({
          icon: "warning",
          title: "Cerraste tu sesión",
        });
        navigate("/Ingreso");

        localStorage.removeItem("currentUser");

        navigate("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  useEffect(() => {
    let isMounted = true;

    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (isMounted) {
          setdataUser(user);
        }

        var idUsuario = user.uid;

        var fetchData = async () => {
          try {
            const docRef = doc(db, "user", idUsuario);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              if (isMounted) { // Verifica si el componente todavía está montado
                setIsAdmin(docSnap.data().isAdmin);
              }
            } else {
              // docSnap.data() will be undefined in this case
            }
          } catch (err) {
            console.error(err);
          }
        };
        fetchData();
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
    var adminData = async () => {
      try {
        const docRef = doc(db, "user", currentUserlocalStorage);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setIsAdmin(docSnap.data().isAdmin);
        } else {
          // docSnap.data() will be undefined in this case
        }
      } catch (err) {
        console.error(err);
      }
    };
    adminData();
  }, []);
  

    const colors = {
    "blue": "#3182CE", 
    "cyan": "#00B5D8", 
    "gray": "#718096", 
    "green": "#38A169", 
    "orange": "#DD6B20", 
    "pink": "#D53F8C", 
    "purple": "#805AD5", 
    "red": "#E53E3E", 
    "teal": "#319795", 
    "yellow": "#D69E2E"};
    const [scroll, setScroll] = useState(false);
    
    const { isOpen, onOpen, onClose } = useDisclosure();
  
    const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");
    const scrollToHero = () => {
    const heroSection = document.querySelector("#hero");
      heroSection.scrollIntoView({ behavior: "smooth" });
    };
    const scrollNosotros = () => {
      const aboutSection = document.querySelector("#nosotros");
      aboutSection.scrollIntoView({ behavior: "smooth" });
    };
    const scrollServicios = () => {
      const experienceSection = document.querySelector("#servicios");
      experienceSection.scrollIntoView({ behavior: "smooth" });
    };
    const scrollContacto = () => {
      const projectsSection = document.querySelector("#contacto");
      projectsSection.scrollIntoView({ behavior: "smooth" });
    };
   
    const changeScroll = () =>
      document.body.scrollTop > 80 || document.documentElement.scrollTop > 80
        ? setScroll(true)
        : setScroll(false);
  
    window.addEventListener("scroll", changeScroll);
  
    const TbLetterComponents = [];
  
  
    return (
      < >
        <Flex style={{boxShadow: "25px 25px 25px rgba(0,0,0,0.3)"}}
          bg={useColorModeValue("gray.50", "gray.40")}
          px={3}
          h={16}
          boxShadow={scroll ? "base" : "none"}
          zIndex="sticky"
          position="fixed"
          as="header"
          alignItems={"center"}
          justifyContent={"space-between"}
          w="100%"
        >
          
          <Link to="/">
            <HStack>
            <img 
              style={{ width: "7em" }}
              src={logo}
              alt="Logo Agencia Volcán"
            /> 
              {TbLetterComponents.map((Component, index) => (
                <Component key={index} color={colors[color]} />
              ))}
            </HStack>
          </Link>
  
          <Flex >
            <Stack direction={"colum"} spacing={99}>
              {isLargerThanMD ? (
                <>
                  {dataUser && Object.keys(dataUser).length !== 0  ? (
                    // Si el usuario está logueado, muestra el avatar 
                    <Menu>
                      <MenuButton 
                        as={Button}
                        rounded={"full"}
                        variant={"link"}
                        cursor={"pointer"}
                        minW={0}
                      >
                        <Avatar
                            size={"sm"}
                            src={dataUser?.photoURL}
                          ></Avatar>
                      </MenuButton>

                      <MenuList>
                        <br />
                        <Center>
                          <Avatar size={"lg"} src={dataUser?.photoURL} />
                        </Center>
                        <Center>
                          {dataUser?.displayName}

                          {/* <p>{dataUser.displayNam}</p> */}
                        </Center>
                        
                        <MenuDivider />
                        {isAdmin ? (
                        
                          <MenuItem
                            onClick={() => {
                              navigate("/Administracion");
                            }}
                          >
                            Admin Panel
                          </MenuItem> 
                         
                        ) : (
                          <div></div>
                        )}
                          <MenuItem
                            onClick={() => {
                              navigate("/Ligas");
                            }}
                          >
                          Ligas
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              navigate("/Live");
                            }}
                          >
                            Eventos
                          </MenuItem>

                        <MenuItem
                          onClick={signOutAccount}
                          _hover={{
                            textDecoration: "none",
                            bg: "",
                          }}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  ) : (
                    // Si el usuario no está logueado, muestra el botón de "Ingresar"
                    <Link  to="/Ingreso">
                      <Button color="gray.600"  bg="white" _hover={{
                            textDecoration: "none",
                            bg: "gray.100",
                            borderRadius:"100"
                          }}>
                        <BiUser/>‎ Ingresar
                      </Button>
                    </Link>
                  )}

                </>
              ) : (
                <></>
              )}
  
              {isLargerThanMD ? (
                <></>
              ) : (

                // RESPONSIVE FORMAT
                <Flex>
                  {dataUser && Object.keys(dataUser).length !== 0  ? (
                    // Si el usuario está logueado, muestra el avatar de hamburguesa
                    <Menu>
                      <MenuButton 
                        as={Button}
                        rounded={"full"}
                        variant={"link"}
                        cursor={"pointer"}
                        minW={0}
                      >
                        <Avatar
                            mx={5}
                            size={"sm"}
                            src={dataUser?.photoURL}
                          ></Avatar>
                      </MenuButton>

                      <MenuList>
                        <br />
                        <Center>
                          <Avatar size={"lg"} src={dataUser?.photoURL} />
                        </Center>
                        <Center>
                          {dataUser?.displayName}

                          {/* <p>{dataUser.displayNam}</p> */}
                        </Center>
                      
                        <MenuDivider />
                        {isAdmin ? (
                        
                          <MenuItem
                            onClick={() => {
                              navigate("/Administracion");
                            }}
                          >
                            Admin Panel
                          </MenuItem> 
                         
                        ) : (
                          <div></div>
                        )}
                          <MenuItem
                            onClick={() => {
                              navigate("/Ligas");
                            }}
                          >
                          Ligas
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              navigate("/Live");
                            }}
                          >
                            Eventos
                          </MenuItem>

                        <MenuItem
                          onClick={signOutAccount}
                          _hover={{
                            textDecoration: "none",
                            bg: "",
                          }}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  ) : (
                    // Si el usuario no está logueado, muestra el botón de "Ingresar"
                    <Link  to="/Ingreso">
                      <Button bg="white" _hover={{
                            textDecoration: "none",
                            bg: "gray.100",
                            borderRadius:"100"
                          }}>
                        <BiUser/>‎Ingresar
                      </Button>
                    </Link>
                  )}
                </Flex>
              )}
            </Stack>
          </Flex>
        </Flex>
      </>
    );
  }