import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableContainer,
  Heading,
  useColorModeValue,
  Tfoot,
  Text,
  Flex,
} from "@chakra-ui/react";
import "./Eventos.css";

import {
  doc,
  setDoc,
  collection,
  addDoc,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";

const Eventos = () => {

  
  const bgColor = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("gray.700", "white");
  const columns = [
    {
      id: "title",
      text: "title",
    },
    {
      id: "date",
      text: "date",
    },
    {
      id: "event",
      text: "time",
    },
  ];

  const [eventos, setEventos] = useState([]);
  var arrayEventos = [];

  var fetchEventsData = async () => {
    const eventosLista = await getDocs(collection(db, "eventos"));

    eventosLista.forEach((doc) => {
      var objectData = doc.data();
      objectData.uid = doc.id;

      arrayEventos.push(objectData);
    });
    setEventos(arrayEventos);
    console.log("eventos", eventos);
  };

  let now = new Date();
  useEffect(() => {
    fetchEventsData();
  }, []);
  function prettyDate2(time) {
    var date = new Date(parseInt(time));
    return date.toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  
  return (
    <Box align={"center"} justify={"center"} my="4em">
      <Heading size="3xl">Próximos Eventos</Heading>
      <TableContainer
        boxShadow="dark-lg"
        bgGradient="linear(to-t, brand.green6, brand.blue4, brand.lightBlue5)"
        // bg={useColorModeValue("blue4", "brand.grey1")}
        borderRadius="4"
        maxW={{
          base: "98%", // 0-48em
          md: "70%", // 48em-80em,
          xl: "50%", // 80em+
        }}
        my="2em"
        variant="simple"
      >
        <Table size="sm" className="table-tiny">
          <Thead>
            <Tr
              border="2px solid "
              borderColor="brand.darkblue3"
              background="brand.darkblue3"
            >
              <Th>
                {" "}
                <Text
                  p="0.3em"
                  bgGradient="linear(to-l, brand.green6,brand.blue4)"
                  // background="brand.darkblue3"
                  bgClip="text"
                  fontSize="1em"
                  fontWeight="extrabold"
                >
                  Título
                </Text>
              </Th>
              <Th p={3}>
                {" "}
                <Text
                  p="0.3em"
                  bgGradient="linear(to-l, brand.green6,brand.blue4)"
                  // background="brand.darkblue3"
                  bgClip="text"
                  fontSize="1em"
                  fontWeight="extrabold"
                >
                  Fecha
                </Text>
              </Th>
              <Th p={3}>
                {" "}
                <Text
                  p="0.3em"
                  bgGradient="linear(to-l, brand.green6,brand.blue4)"
                  // background="brand.darkblue3"
                  bgClip="text"
                  fontSize="1em"
                  fontWeight="extrabold"
                >
                  Hora
                </Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody >
            <Tr >
              <Td border="2px solid " borderColor="brand.darkblue3">
                Banfield - Estudiantes{" "}
              </Td>
              <Td border="2px solid " borderColor="brand.darkblue3">
                {" "}
                30/07
              </Td>

              <Td border="2px solid " borderColor="brand.darkblue3">
                <Flex>
                  <Box mr={1}>15:00</Box>
                  <div px={2} class="live"></div>
                  <Box mx={1} color="red" fontSize={8}>
                    Live
                  </Box>
                </Flex>
              </Td>
            </Tr>
            {eventos &&
              eventos?.map((event) => (
                <Tr key={event?.uid}>
                  <Td border="2px solid " borderColor="brand.darkblue3">
                    {event?.title}
                  </Td>
                  <Td border="2px solid " borderColor="brand.darkblue3">
                    {event?.date}
                  </Td>
                  <Td border="2px solid " borderColor="brand.darkblue3">
                    {event?.time}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Eventos;
