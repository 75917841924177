import React from "react";
import AnimationRevealPage from "../../feauteres/AnimationRevealPage";
import Hero from "../../navBar/NavBarLive";
import Footer from "../../feauteres/Footer";
import Liga from "../live/Liga.js"



export default () => {
  return (
    <>
     <Hero />
    <AnimationRevealPage>
      <Liga/>
      
      <Footer />
    </AnimationRevealPage>
    </>
  );
}
