import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "../../feauteres/Headings.js";
import { SectionDescription } from "../../feauteres/Typography.js";
import { Card, Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon, Stack, CardHeader, Box, CardBody, Text, StackDivider, Heading,Divider, Tabs, TabList, TabPanels, Tab, TabPanel  } from '@chakra-ui/react'
import { StarIcon, SmallAddIcon, TimeIcon } from '@chakra-ui/icons'
import {
  getDocs,
  collection,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";



const Container = tw.div`relative bg-logo-300 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;
const HeadingTw = tw(SectionHeading)`w-full`;
const VerticalSpacer = tw.div`mt-10 w-full`;
 


export default ({
  headingTw = "Ligas",
  subheading = "",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."

}) => {

  const [ligas, setLigas] = useState([]);

  var arrayLigas = [];
  var fetchData = async () => {
    const ligasLista = await getDocs(collection(db, "ligas"));

    ligasLista.forEach((doc) => {
      var objectData = doc.data();
      objectData.uid = doc.id;

      arrayLigas.push(objectData);
    });
    setLigas(arrayLigas);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      <br></br>
      <br></br>
        {subheading && <Subheading>{subheading}</Subheading>}
        <HeadingTw> <StarIcon/> {headingTw} </HeadingTw> 
        <br></br>
        <br></br>
      <Card w="100%" style={{boxShadow: "25px 25px 15px rgba(0,0,0,0.3)"}} textAlign="center">
      <Accordion allowToggle>

      {ligas && ligas?.map((liga) => (
      <AccordionItem key={liga?.uid}>
      <h2>
        <AccordionButton _expanded={{ bg: '#0293D4', color: 'white' }}>
          <Box as="b" flex='1' textAlign='left' >
          {liga?.title}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
      <iframe
          width="100%"
          height="810em"
          src={liga?.iframeURL}
          title="Mejores FINALES por el ASCENSO del Fútbol CHILENO PT 2 | DeChilena14"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
      </AccordionPanel>
    </AccordionItem>
      ))}
        </Accordion>
          {/* <Tabs  isFitted variant='enclosed'>
            <TabList>
              <Tab _selected={{ color: 'white', bg: '#0293D4' }}> <Text  as='b'>Liga Rukapillan</Text></Tab>
              <Tab  _selected={{ color: 'white', bg: '#0293D4'  }}><Text  as='b'> Liga Femenina Rukapillan</Text></Tab>
              <Tab  _selected={{ color: 'white', bg: '#0293D4'  }}><Text  as='b'> Liga Femenina Rukapillan</Text></Tab>
            </TabList>
            <TabPanels>
              <TabPanel w="100%">
              <iframe
                width="100%"
                height="810em"
                src="https://copafacil.com/-nvglww8eqfb6teldr2n"
                title="Mejores FINALES por el ASCENSO del Fútbol CHILENO PT 2 | DeChilena14"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
              </TabPanel>
              <TabPanel>
                <p>two!</p>
              </TabPanel>
            </TabPanels>
          </Tabs> */}
        </Card>
        <VerticalSpacer />
        <br></br><br></br><br></br>
    </Container>
  );
};
