import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { auth, db } from "../../../firebase";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "../../feauteres/Headings.js";
import { SectionDescription } from "../../feauteres/Typography.js";
import {
  getDocs,
  collection,
} from "firebase/firestore";

import { Card,  Stack, CardHeader, Box, CardBody, Text, StackDivider, Heading,Divider  } from '@chakra-ui/react'
import { CalendarIcon, SmallAddIcon, TimeIcon } from '@chakra-ui/icons'


const Container = tw.div`relative bg-logo-300 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const HeadingTw = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;



export default ({
  cards = null,
  headingTw = "Próximos Eventos",
  subheading = "",
  description = "Te invitamos a disfrutar de próximas programaciones..."
}) => {


 
  const [eventos, setEventos] = useState([]);

  var arrayEventos = [];
  var fetchData = async () => {
    const eventosLista = await getDocs(collection(db, "eventos"));

    eventosLista.forEach((doc) => {
      var objectData = doc.data();
      objectData.uid = doc.id;

      arrayEventos.push(objectData);
    });
    setEventos(arrayEventos);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return ( 
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <HeadingTw>{headingTw}</HeadingTw>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
            <Card w="85%" style={{boxShadow: "25px 25px 15px rgba(0,0,0,0.3)"}} textAlign="center">
              {eventos && eventos?.map((event) => (
              <CardBody key={event?.uid}>
                <Stack spacing='3'>
                  <Box >
                    <Heading size='md'>
                    <SmallAddIcon/>‎ {event?.title} 
                    </Heading>
                    <Text pt='4' fontSize='md' >
                    <CalendarIcon/> ‎ {event?.date}  -  <TimeIcon/> ‎ {event?.time}hs Chile
                    </Text>
                  </Box>
                  <Divider />
                </Stack>
                
              </CardBody>
              ))}
             
            </Card>
      </ThreeColumnContainer>
    </Container>
  );
};
