import React from "react";
import AnimationRevealPage from "../../feauteres/AnimationRevealPage";
import Hero from "../../navBar/NavBarLive";
import Transmition from "./Transmition";
import NextEvents from "./NextEvents";
import Footer from "../../feauteres/Footer";
import Liga from "./Liga"



export default () => {
  return (
    <>
     <Hero />
    <AnimationRevealPage>
      <Transmition />  
      <NextEvents />  
      <Footer />
    </AnimationRevealPage>
    </>
  );
}
