import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../../feauteres/Headings.js";
import { auth, db } from "../../../firebase";
import Swal from "sweetalert2";
import {
  addDoc,
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  orderBy,
  serverTimestamp,
  onSnapshot,
  where,
} from "firebase/firestore";
import { signOut, onAuthStateChanged } from "firebase/auth";
import {
  Box,
  Flex,
  useColorModeValue,
  Button,
  Input,
  FormControl,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Avatar,
  Text,
} from "@chakra-ui/react";
import { BiChat } from "react-icons/bi";
import { ChatIcon, AddIcon, WarningIcon } from "@chakra-ui/icons";

const Container = tw.div`relative bg-gray-900 -mx-8 px-8 text-gray-100`;
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;

const VerticalSpacer = tw.div`mt-10 w-full`;

export default ({ heading = "En Vivo", subheading = "" }) => {
  // const [dataUser, setdataUser] = useState({});
  // const [isAdmin, setIsAdmin] = useState(false);

  // const currentUserlocalStorage = JSON.parse(
  //   localStorage.getItem("currentUser")
  // );

  // useEffect(() => {
  //   let isMounted = true;

  //   onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       if (isMounted) {
  //         setdataUser(user);
  //       }

  //       var idUsuario = user.uid;

  //       var fetchData = async () => {
  //         try {
  //           const docRef = doc(db, "user", idUsuario);
  //           const docSnap = await getDoc(docRef);
  //           if (docSnap.exists()) {
  //             if (isMounted) { // Verifica si el componente todavía está montado
  //               setIsAdmin(docSnap.data().isAdmin);
  //             }
  //           } else {
  //             // docSnap.data() will be undefined in this case
  //           }
  //         } catch (err) {
  //           console.error(err);
  //         }
  //       };
  //       fetchData();
  //       // ...
  //     } else {
  //       // User is signed out
  //       // ...
  //     }
  //   });
  //   var adminData = async () => {
  //     try {
  //       const docRef = doc(db, "user", currentUserlocalStorage);
  //       const docSnap = await getDoc(docRef);
  //       if (docSnap.exists()) {
  //         setIsAdmin(docSnap.data().isAdmin);
  //       } else {
  //         // docSnap.data() will be undefined in this case
  //       }
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };
  //   adminData();
  // }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  // useEffect(() => {
  //   var elem = document.getElementById("data");
  //   elem.scrollTop = elem.scrollHeight;
  // }, [messages]);

  const [postValidate, setPostValidate] = useState(false);
  const requeridoPost = (e) => {
    let expresion = /^[\w\s\p{P}\p{S}]+$/gu;

    if (
      newMessage != null &&
      newMessage != "" &&
      newMessage.trim() != null &&
      expresion.test(newMessage)
    ) {
      setPostValidate(false);
    } else {
      setPostValidate(true);
    }
  };

  const handleChange = () => (e) => {
    setNewMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (auth.currentUser) {
      if (postValidate == false && newMessage != null && newMessage != "") {
        addDoc(collection(db, "postMessages"), {
          message: newMessage,
          createdAt: serverTimestamp(),
          userMessage: auth.currentUser.displayName,
          userPhotoURL: auth.currentUser.photoURL,
        });
        setNewMessage("");
        let timerInterval;
        Swal.fire({
          title: "Mensaje Enviado!",
          html: " <strong></strong> ",
          timer: 1000,
          onBeforeOpen: () => {
            Swal.showLoading();
            timerInterval = setInterval(() => {
              Swal.getHtmlContainer().querySelector("strong").textContent =
                Swal.getTimerLeft();
            }, 100);
          },
          onClose: () => {
            clearInterval("");
          },
        }).then((result) => {
          if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.timer
          ) {
            console.log("I was closed by the timer");
          }
        });
      } else {
        setPostValidate(true);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "¡Ingresa un mensaje!",
        });
      }
    }
  };
  
  const [eventActive, setEventActive] = useState("");
  
  var fetchDataVideoURL = async () => {
    const eventosRef = collection(db, "eventos");
    const q = query(eventosRef, where("status", "==", "Active"));
    console.log("EVENTO ACTIVOOO ", q);

    const querySnapshot = await getDocs(q);
    let video = [];
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      video.push(doc.data())
    });
    
    setEventActive(video[0].videoURL);
    console.log("EVENTO ACTIVOOO ", eventActive);
  };


  useEffect(() => {
    fetchDataVideoURL();
    const queryMessages = query(
      collection(db, "postMessages"),
      orderBy("createdAt")
    );
    const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
      let messages = [];
      snapshot.forEach((doc) => {
        messages.push({ ...doc.data(), id: doc.id });
      });
      setMessages(messages);
    });
    console.log(messages);
    return () => unsuscribe();
  }, []);

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <VerticalSpacer />
        <iframe
          style={{ boxShadow: "25px 25px 15px rgba(0,0,0,0.3)" }}
          width="100%"
          height="510em"
          src={eventActive}
          title="Mejores FINALES por el ASCENSO del Fútbol CHILENO PT 2 | DeChilena14"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>

        <Button
          size="lg"
          ref={btnRef}
          mt={12}
          colorScheme="teal"
          onClick={onOpen}
        >
          {" "}
          <BiChat />‎ ‎ Live Chat{" "}
        </Button>

        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          size="sm"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              {" "}
              <ChatIcon /> Live Chats
            </DrawerHeader>
            <DrawerBody ml={-5}>
              {messages &&
                messages?.map((postMess) => (
                  <Flex mb={3}>
                    <Avatar
                      mx={4}
                      size={"xs"}
                      src={postMess?.userPhotoURL}
                    ></Avatar>
                    <Text color="#1FB59A" as="b">
                      {postMess?.userMessage}: ‎{" "}
                    </Text>
                    <Box> {postMess?.message}</Box>
                  </Flex>
                ))}
            </DrawerBody>

            <form onSubmit={handleSubmit}>
              <DrawerFooter mb={6}>
                <FormControl id="message" isInvalid={postValidate}>
                  <Input
                    
                    w="100%"
                    placeholder="Escribe aquí..."
                    onChange={handleChange()}
                    value={newMessage}
                    // onBlur={requeridoPost}
                  />
                </FormControl>
                <Button
                  ml={2}
                  type="submit"
                  bg={useColorModeValue("purple.300", "brand.grey1")}
                  bl="2px"
                  color="white"
                  fontWeight="bold"
                  borderRadius="md"
                  bgGradient="linear(to-r, brand.green6, brand.lightBlue5)"
                  _hover={{
                    bgGradient: "linear(to-r, brand.darkblue3, brand.green6)",
                  }}
                >
                  Enviar
                </Button>
              </DrawerFooter>
            </form>
          </DrawerContent>
        </Drawer>
      </ThreeColumnContainer>
    </Container>
  );
};
