import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  IoAnalyticsSharp,
  IoLogoBitcoin,
  IoSearchSharp,
} from "react-icons/io5";
import { ReactElement } from "react";
import SaaSProductLandingPage from "../SaaSProductLandingPage";


export default function SplitWithImage() {
  return (
    <div id="Servicios">
      <SaaSProductLandingPage></SaaSProductLandingPage>        
      
    </div>
      

      
  );
}
