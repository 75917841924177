import {
    Button,
    Flex,
    Heading,
    Image,
    Stack,
    Text,
    useBreakpointValue,
  } from "@chakra-ui/react";
  
  export default function SplitScreen() {
    return (
      <Stack
        id="Nosotros"
        minH={"100vh"}
        direction={{ base: "column", md: "row" }}
      >
        <Flex flex={1}>
          <Image
            alt={"Login Image"}
            objectFit={"cover"}
            src={
              "https://d3b5jqy5xuub7g.cloudfront.net/wp-content/uploads/2021/12/Potrero-f%C3%BAtbol-min.png"
            }
          />
        </Flex>
        <Flex p={8} flex={1} align={"center"} justify={"center"}>
          <Stack spacing={6} w={"full"} maxW={"lg"}>
            <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>
              <Text
                as={"span"}
                position={"relative"}
                _after={{
                  content: "''",
                  width: "full",
                  height: useBreakpointValue({ base: "20%", md: "30%" }),
                  position: "absolute",
                  bottom: 1,
                  left: 0,
                  bg: "blue.400",
                  zIndex: -1,
                }}
              >
                ANFA Villarrica
              </Text>
              <br />{" "}
              <Text color={"blue.400"} as={"span"}>
                Asociación de futbol de Villarrica
              </Text>{" "}
            </Heading>
            <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.500"}>
              POEMA AL FUTBOL, de Walter Saavedra Cómo vas a saber lo que es el
              amor si nunca te hiciste hincha de un club. Cómo vas a saber lo que
              es el dolor si jamás el zaguero te rompió la tibia y el peroné y
              estuviste en una barrera y la pelota te pego justo ahí. Cómo vas a
              saber lo que es el placer si nunca diste una vuelta olímpica de
              visitante. Cómo vas a saber lo que es el cariño si nunca la
              acariciaste de chanfle entrándole con el revés del pie para dejarla
              jadeando bajo la red. escuchame... cómo vas a saber lo que es la
              solidaridad si jamás saliste a dar la cara por un compañero golpeado
              desde atrás. Cómo vas a saber lo que es la poesía si jamás tiraste
              una gambeta. Cómo vas a saber lo que es la humillación si jamás te
              metieron un caño. Cómo vas a saber lo que es la amistad si nunca
              devolviste una pared. Cómo vas a saber lo que es el pánico. si nunca
              te sorprendieron mal parado en un contragolpe. Cómo vas a saber lo
              que es morir un poco si jamás fuiste a buscar la pelota adentro del
              arco. Decime viejo... Cómo vas a saber, querido amigo, CÓMO VAS A
              SABER LO QUE ES LA VIDA, SI NUNCA JAMÁS, JUGASTE AL FÚTBOL.
            </Text>
            <Stack direction={{ base: "column", md: "row" }} spacing={4}>
              <Button
                rounded={"full"}
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Acción
              </Button>
              <Button rounded={"full"}>Botón</Button>
            </Stack>
          </Stack>
        </Flex>
      </Stack>
    );
  }
  