import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentUser } from "../../store/currentUser";
import Navbar from "../navBar/Navbar";
import NewNavbar from '../navBar/NewNavbar'
import Live from "../secciones/live/Live";
import Contacto from "../secciones/contacto/Contacto";
import Nosotros from "../secciones/nosotros/Nosotros";
import Servicios from "../secciones/servicios/Serviciosa";
import Eventos from "../secciones/eventos/Eventos";



import {
  Box,
  Button,
  ButtonProps,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsSun, BsMoonStarsFill } from "react-icons/bs";

const Home = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const navigate = useNavigate();
   

  useEffect(() => {
    // if (currentUser) {
    //   db.collection("user")
    //     .where("id", "==", currentUser.id)
    //     .get()
    //     .then((doc) =>
    //       doc.forEach((data) => {
    //         localStorage.setItem("currentUser", JSON.stringify(data.data()));
    //       })
    //     );
    // }
  }, []);
  return (
    <>
      {/* {!currentUser ? (
       navigate("/Login")
      ) : ( */}
      <Box id="hero" bg={useColorModeValue("White", "gray.700")}>

        <NewNavbar />
        <Box w="100%" h="10">
         </Box>
        <Servicios />    
      </Box>
      {/* )} */}
    </>
  );
};

export default Home;
