import React, { ReactNode, useState } from 'react'
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
} from '@chakra-ui/react'
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
} from 'react-icons/fi'
import { IconType } from 'react-icons'
import { Link } from 'react-router-dom';
import { ReactText } from 'react'
import Eventos from '../eventos/Eventos'
import RedesSociales from './RedesSociales'
import Resultados from './Resultados'
import Logo from '../../../assets/logo.png'
import NavBar from "../../navBar/NavBarLive"

interface LinkItemProps {
  name: string,
  icon: IconType
}
const LinkItems: Array<LinkItemProps> = [
  { name: 'Eventos', icon: FiHome },
  { name: 'Redes Sociales', icon: FiCompass },
  { name: 'Ligas', icon: FiStar }
]

export default function SimpleSidebar() {
 
  const [openTabSection, setOpenTabSection] = useState('Eventos')


  const openSection=(sectionName)=>{
    setOpenTabSection(sectionName)
    
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>

      <div  display={{ base: 'none', md: 'block' }} >

        <Box
        bg={useColorModeValue('white', 'gray.900')}
        borderRight="1px"
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        w={{ base: 'full', md: 60 }}
        pos="fixed"
        h="full">
        <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Box fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </Box>
          <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
        </Flex>
        {LinkItems.map((link) => (
          <NavItem key={link.name} icon={link.icon} onClick={()=>openSection(link.name)}>
            {link.name}
          </NavItem>
        ))}
      </Box>

      </div>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">

        {openTabSection == 'Eventos' && 
        <Eventos></Eventos> || openTabSection == 'Ligas' && 
        <Resultados></Resultados> || openTabSection == 'Redes Sociales' && 
        <RedesSociales></RedesSociales>}
      </Box>
    </Box>
  )
}



interface NavItemProps extends FlexProps {
  icon: IconType,
  children: ReactText
}
const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
  return (
    <Box
      as="a"
      href="#"
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  )
}

interface MobileProps extends FlexProps {
  onOpen: () => void
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}>
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        Logo
      </Text>
    </Flex>
  )
}