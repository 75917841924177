import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Card,
  Button,
  CardHeader,
  Heading,
  FormControl,
  Input,
  Badge,
  Select,
  Box,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
} from "@chakra-ui/react";
import { BsThreeDotsVertical, BsChatSquareQuote } from "react-icons/bs";
import { RiShutDownLine, RiRestartLine, RiFileShredLine } from "react-icons/ri";
import { FiEdit2, FiTrash2, FiPlus } from "react-icons/fi";
import { BiLinkAlt } from "react-icons/bi";


import Swal from "sweetalert2";

import {
  doc,
  setDoc,
  collection,
  addDoc,
  getDocs,
  onSnapshot,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";

const Eventos = () => {
  // modal logic New Event
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  // carga evento logic

  const [formData, setFormData] = useState({
    title: "",
    date: "",
    time: "",
    status: "",
    videoURL:""
  });
  const { title, date, time, status, videoURL } = formData;

  const handleChange = (text) => (e) => {
    setFormData({ ...formData, [text]: e.target.value });
    console.log("formdata0", formData);
  };

  // Edit evento logic

  const [eventos, setEventos] = useState([]);

  const [titleValidate, setTitleValidate] = useState(false);
  const [dateValidate, setDateValidate] = useState(false);
  const [timeValidate, setTimeValidate] = useState(false);

  // Inserta evento logic

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      titleValidate == false &&
      title.length > 2 &&
      title != null &&
      title != "" &&
      dateValidate == false &&
      date.length > 2 &&
      date != null &&
      date != "" &&
      time.length > 2 &&
      time != null &&
      time != "" &&
      timeValidate == false
    ) {
      var data = {
        title,
        date,
        time,
        status: status ? status : 'Active',
        videoURL,
      };
      addDoc(collection(db, "eventos"), data);
      fetchData();
      setFormData({
        title: "",
        date: "",
        time: "",
        status: "",
        videoURL:"",
      });
      Swal.fire("Evento creado", "", "success");
      handleClose();
    } else {
      Swal.fire("Debes ingresar los datos del evento", "", "info");
      handleClose();
    }
  };
  const requeridoTitle = (e) => {
    let expresion = /^[A-Za-z0-9\s]+$/g;

    if (
      title.length > 2 &&
      title != null &&
      title != "" &&
      // title.trim() != null &&
      expresion.test(title)
    ) {
      setTitleValidate(false);
    } else {
      setTitleValidate(true);
    }
  };
  const requeridoDate = (e) => {
    // const expresion = /^(0[1-9]|[1-2]\d|3[01])(\/)(0[1-9]|1[012])\2(\d{4})$/;

    if (
      date.length > 2 &&
      date != null &&
      date != "" &&
      date.trim() != null
      // expresion.test(date)
    ) {
      setDateValidate(false);
    } else {
      setDateValidate(true);
    }
  };
  const requeridoTime = (e) => {
    let expresion = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (
      time.length > 2 &&
      time != null &&
      time != "" &&
      time.trim() != null &&
      expresion.test(time)
    ) {
      setTimeValidate(false);
    } else {
      setTimeValidate(true);
    }
  };

  var arrayEventos = [];
  var fetchData = async () => {
    const eventosLista = await getDocs(collection(db, "eventos"));

    eventosLista.forEach((doc) => {
      var objectData = doc.data();
      objectData.uid = doc.id;

      arrayEventos.push(objectData);
    });
    setEventos(arrayEventos);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteEvent = async (idToDelete) => {
    await deleteDoc(doc(db, "eventos", idToDelete));

    fetchData();
    Swal.fire("Evento Eliminado", "", "success");
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    console.log("EAAAA", idToEdit);
    const data = {
      title: titleToEdit,
      date: dateToEdit,
      time: timeToEdit,
      status: statusToEdit,
      videoURL: videoURLToEdit,
    };
    console.log("idToEdit", idToEdit);
    console.log("data", data);
    await setDoc(doc(db, "eventos", idToEdit), data);

    fetchData();

    setIsOpenEdit(false);
    Swal.fire("Evento modificado", "", "success");
    setFormDataEdit({
      idToEdit: "",
      titleToEdit: "",
      dateToEdit: "",
      timeToEdit: "",
      statusToEdit: "",
      videoURLToEdit: ""
    });
  };

  // modal logic edit Event
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const handleOpenEdit = async (idRecordToEdit) => {
    // const docRef = await doc(db, "eventos", idRecordToEdit);

    console.log("idRecordToEdit 11", idRecordToEdit);
    console.log("formDataEdit 11", formDataEdit);
    const docRef = await doc(db, "eventos", idRecordToEdit);
    console.log("docRef", docRef);
    const docSnap = await getDoc(docRef);
    console.log("docSnap", docSnap);

    if (docSnap.exists()) {
      var titleToEditBox = docSnap.data().title;
      var dateToEditBox = docSnap.data().date;
      var timeToEditBox = docSnap.data().time;
      var statusToEditBox = docSnap.data().status;
      var videoURLToEditBox = docSnap.data().videoURL;
    }
    setFormDataEdit({
      ...formDataEdit,
      idToEdit: idRecordToEdit,
      titleToEdit: titleToEditBox,
      dateToEdit: dateToEditBox,
      timeToEdit: timeToEditBox,
      statusToEdit: statusToEditBox,
      videoURLToEdit : videoURLToEditBox
    });
    // ({ idToEdit: idRecordToEdit });
    console.log("formDataEdit22 ", formDataEdit);
    // const docSnap = await getDoc(docRef);

    setIsOpenEdit(true);
  };
  const handleCloseEdit = () => setIsOpenEdit(false);

  const [formDataEdit, setFormDataEdit] = useState({
    idToEdit: "",
    titleToEdit: "",
    dateToEdit: "",
    timeToEdit: "",
    statusToEdit: "",
    videoURLToEdit : "",
  });

  const { titleToEdit, dateToEdit, timeToEdit, idToEdit, statusToEdit, videoURLToEdit } =
    formDataEdit;
  const handleChangeEdit = (text) => (e) => {
    setFormDataEdit({ ...formDataEdit, [text]: e.target.value });
    console.log("edit form  ", formDataEdit);
  };

  return (
    <>
      <Card m={3}>
        <CardHeader bg="#5CCFF1" >
          <Heading align="center" size="lg">
            {" "}
            Tabla de Eventos
          </Heading>
        </CardHeader>
      </Card>
      <Box textAlign="right">
        <Button
          onClick={handleOpen}
          textAlign="right"
          colorScheme="blue"
          variant="outline"
          size="lg"
          m={2}
        >
          <FiPlus></FiPlus>‎ Nuevo Evento
        </Button>
      </Box>

      <Card>
        <TableContainer>
          <Table size="lg">
            <Thead bg="#5CCFF1">
              <Tr>
                <Th textAlign="center">Nombre del Evento</Th>
                <Th textAlign="center">Fecha</Th>
                <Th textAlign="center">Hora</Th>
                <Th textAlign="center">Estado</Th>
                <Th textAlign="center">Video URL</Th>
                <Th textAlign="center">Acciónes</Th>
              </Tr>
            </Thead>
            <Tbody>
              {eventos &&
                eventos?.map((event) => (
                  <Tr key={event?.uid}>
                    <Td>{event?.title}</Td>
                    <Td>{event?.date}</Td>
                    <Td>{event?.time}</Td>
                    <Td textAlign="center">
                      {event?.status == "Active" && (
                        <Badge variant="subtle" colorScheme="green">
                          Activo
                        </Badge>
                      )}
                      {event?.status == "Inactive" && (
                        <Badge variant="subtle" colorScheme="red">
                          Inactive
                        </Badge>
                      )}
                    </Td>
                    <Td>{event?.videoURL}</Td>
                    <Td textAlign="center">
                      <Button
                        colorScheme="blue"
                        size="sm"
                        m={2}
                        onClick={() => handleOpenEdit(event?.uid)}
                      >
                        <FiEdit2></FiEdit2>
                      </Button>
                      <Button
                        colorScheme="red"
                        size="sm"
                        m={2}
                        onClick={() => handleDeleteEvent(event?.uid)}
                      >
                        <FiTrash2></FiTrash2>
                      </Button>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Card>
      <Modal size="xl" isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit}>
            <ModalHeader textAlign="center">Nuevo Evento</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl mt={4} id="title" isInvalid={titleValidate}>
                <FormLabel>Título</FormLabel>
                <Input
                  type="text"
                  onChange={handleChange("title")}
                  value={title}
                  onBlur={requeridoTitle}
                  variant="flushed"
                  placeholder="Ej: Noticias"
                />
                {!titleValidate ? (
                  <div></div>
                ) : (
                  <FormErrorMessage>
                    Ingrese un título por favor.
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={4} id="date" isInvalid={dateValidate}>
                <FormLabel>Fecha</FormLabel>
                <Input
                  type="date"
                  onChange={handleChange("date")}
                  value={date}
                  onBlur={requeridoDate}
                  variant="flushed"
                  placeholder="Fecha"
                />
                {!dateValidate ? (
                  <div></div>
                ) : (
                  <FormErrorMessage>
                    Ingrese una fecha por favor.
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={4} id="time" isInvalid={timeValidate}>
                <FormLabel>Hora</FormLabel>
                <Input
                  type="time"
                  onChange={handleChange("time")}
                  value={time}
                  onBlur={requeridoTime}
                  variant="flushed"
                  placeholder="Hora"
                />
                {!timeValidate ? (
                  <div></div>
                ) : (
                  <FormErrorMessage>
                    Ingrese un horario por favor.
                  </FormErrorMessage>
                )}
              </FormControl>{" "}
              <FormControl mt={4} id="videoURL" >
                <FormLabel>Video URL</FormLabel>
                <Input
                  type="text"
                  onChange={handleChange("videoURL")}
                  value={videoURL}
                  variant="flushed"
                  placeholder="https//youtube.com"
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Estado</FormLabel>
                <Select variant="flushed" onChange={handleChange("status")} value={status}>
                  <option value="Active">Activo</option>
                  <option value="Inactive">Inactivo</option>
                </Select>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                Guardar
              </Button>
              <Button onClick={handleClose}>Cancelar</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Modal size="xl" isOpen={isOpenEdit} onClose={handleCloseEdit}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmitEdit}>
            <ModalHeader textAlign="center">Editar Evento</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl mt={4} id="titleToEdit">
                <FormLabel>Título</FormLabel>
                <Input
                  type="text"
                  onChange={handleChangeEdit("titleToEdit")}
                  value={titleToEdit}
                  onBlur={requeridoTitle}
                  variant="flushed"
                  placeholder={titleToEdit}
                />
              </FormControl>
              <FormControl mt={4} id="dateToEdit">
                <FormLabel>Fecha</FormLabel>
                <Input
                  type="date"
                  onChange={handleChangeEdit("dateToEdit")}
                  value={dateToEdit}
                  onBlur={requeridoDate}
                  variant="flushed"
                  placeholder={dateToEdit}
                />
              </FormControl>
              <FormControl mt={4} id="timeToEdit">
                <FormLabel>Hora</FormLabel>
                <Input
                  type="time"
                  onChange={handleChangeEdit("timeToEdit")}
                  value={timeToEdit}
                  onBlur={requeridoTime}
                  variant="flushed"
                  placeholder={timeToEdit}
                />
              </FormControl>{" "}
              <FormControl mt={4} id="videoURL" >
                <FormLabel>Video URL</FormLabel>
                <Input
                  type="text"
                  onChange={handleChangeEdit("videoURLToEdit")}
                  value={videoURLToEdit}
                  variant="flushed"
                  placeholder={videoURLToEdit}
                />
              </FormControl>              
              <FormControl mt={4}>
                <FormLabel>Estado</FormLabel>
                <Select
                  variant="flushed"
                  onChange={handleChangeEdit("statusToEdit")}
                  defaultValue={statusToEdit}
                >
                  <option value="Active">Activo</option>
                  <option value="Inactive">Inactivo</option>
                </Select>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                Guardar
              </Button>
              <Button onClick={handleCloseEdit}>Cancelar</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Eventos;
