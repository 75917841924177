import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  HStack,
  Card,
  Heading,
  CardHeader,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  Modal,
  Input,
  Select,
  FormControl,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormLabel,
} from "@chakra-ui/react";
import { FiEdit2, FiTrash2, FiPlus } from "react-icons/fi";
import { EditIcon, AddIcon, WarningIcon } from "@chakra-ui/icons";
import { CgInstagram } from "react-icons/cg";
import { FaCheckCircle } from "react-icons/fa";
import {
  InstagramEmbed,
  FacebookEmbed,
  TwitterEmbed,
} from "react-social-media-embed";
import {
  doc,
  setDoc,
  collection,
  addDoc,
  getDocs,
  onSnapshot,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";
import Swal from "sweetalert2";

interface Props {
  children: React.ReactNode;
}

function PriceWrapper(props: Props) {
  const { children } = props;

  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}

export default function ThreeTierPricing() {

  function isValidURL(string) {
    var res = string.match(/(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/);
    return (res !== null)
  };

  const [postRedes, setPostRedes] = useState({ 
    idPostRedesDataFetch: "",
    primerPostDataFetch: "",
    segundoPostDataFetch: "",
  });
  const { idPostRedesDataFetch, primerPostDataFetch, segundoPostDataFetch } =
    postRedes;

  useEffect(() => {
    fetchData();
  }, []);

  var fetchData = async () => {
    const postRedesLista = await getDocs(collection(db, "postRedes"));
    var objectData;
    postRedesLista.forEach((doc) => {
      objectData = doc.data();
      objectData.id = doc.id;
    });
    // console.log("postRedesLista " + JSON.stringify(objectData.id));

    setPostRedes({
      ...postRedes,
      idPostRedesDataFetch: objectData?.id,
      primerPostDataFetch: objectData?.primerPost,
      segundoPostDataFetch: objectData?.segundoPost,
    });
    console.log("postRedes " + JSON.stringify(postRedes));
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const [formDataEdit, setFormDataEdit] = useState({
    idPostRedes: "",
    primerPost: "",
    segundoPost: "",
  });

  const { primerPost, segundoPost, idPostRedes } = formDataEdit;
  const handleChangeEdit = (text) => (e) => {
    setFormDataEdit({ ...formDataEdit, [text]: e.target.value });
    console.log("edit form  ", formDataEdit);
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    console.log("EAAAA", idPostRedesDataFetch);
    const data = {
      primerPost,
      segundoPost,
    };
    if (data.primerPost == "") {
      data.primerPost = primerPostDataFetch;
    }
    if (data.segundoPost == "") {
      data.segundoPost = segundoPostDataFetch;
    }

    console.log("data", data);
    await setDoc(doc(db, "postRedes", idPostRedesDataFetch), data);

    fetchData();

    setIsOpen(false);
    Swal.fire("Evento modificado", "", "success");
    setFormDataEdit({
      primerPost: "",
      segundoPost: "",
    });
  };

  return (
    <>
      <Card m={3}>
        <CardHeader bg="#0293D4">
          <Heading align="center" size="lg" color="white">
            Post de Redes Sociales
          </Heading>
        </CardHeader>
      </Card>

      <Box textAlign="right">
        <Button
          onClick={handleOpen}
          textAlign="right"
          colorScheme="blue"
          variant="outline"
          size="lg"
          m={4}
        >
          <EditIcon m={3}></EditIcon> Editar Links{" "}
        </Button>
      </Box>
      <Card>
        <CardHeader bg="#0293D4">
          <Heading align="center" size="lg" color="white">
            Vista Previa{" "}
          </Heading>
        </CardHeader>
        <Box py={12}>
          <Stack
            direction={{ base: "column", md: "row" }}
            textAlign="center"
            justify="center"
            spacing={{ base: 4, lg: 10 }}
            py={10}
          >
            <PriceWrapper>
              <Box
                position="relative"
                bg={useColorModeValue("gray.50", "gray.700")}
              >
                <Box
                  position="absolute"
                  top="-16px"
                  left="50%"
                  style={{ transform: "translate(-50%)" }}
                >
                  <Text
                    textTransform="uppercase"
                    bg={useColorModeValue("#17B5B1", "#17B5B1")}
                    px={3}
                    py={1}
                    color={useColorModeValue("gray.900", "gray.300")}
                    fontSize="sm"
                    fontWeight="600"
                    rounded="xl"
                  >
                    Primer Post
                  </Text>
                </Box>
                <Box py={4} px={12}>
                {primerPostDataFetch && isValidURL(primerPostDataFetch) 
                  ? <InstagramEmbed url={primerPostDataFetch} width={328} />
                  : <Text>No se puede visualizar porque el link es incorrecto</Text>
                }
                </Box>
              </Box>
            </PriceWrapper>
            <PriceWrapper>
              <Box
                position="relative"
                bg={useColorModeValue("gray.50", "gray.700")}
              >
                <Box
                  position="absolute"
                  top="-16px"
                  left="50%"
                  style={{ transform: "translate(-50%)" }}
                >
                  <Text
                    textTransform="uppercase"
                    bg={useColorModeValue("#5CCFF1", "#5CCFF1")}
                    px={3}
                    py={1}
                    color={useColorModeValue("gray.900", "gray.300")}
                    fontSize="sm"
                    fontWeight="600"
                    rounded="xl"
                  >
                    Segundo Post
                  </Text>
                </Box>
                <Box py={4} px={12}>
                {segundoPostDataFetch && isValidURL(segundoPostDataFetch) 
                  ? <InstagramEmbed url={segundoPostDataFetch} width={328} />
                  : <Text>No se puede visualizar porque el link es incorrecto</Text>
                }
                </Box>
              </Box>
            </PriceWrapper>
          </Stack>
        </Box>
      </Card>

      <Modal size="xl" isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmitEdit}>
            <ModalHeader textAlign="center">Posteos de Instagram </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel> Link del Primer Post</FormLabel>
                <Input
                  mb={9}
                  variant="flushed"
                  placeholder={primerPostDataFetch}
                  onChange={handleChangeEdit("primerPost")}
                  value={primerPost}
                />
              </FormControl>
              <FormControl>
                <FormLabel> Link del Segundo Post</FormLabel>
                <Input
                  variant="flushed"
                  placeholder={segundoPostDataFetch}
                  onChange={handleChangeEdit("segundoPost")}
                  value={segundoPost}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                Guardar
              </Button>
              <Button onClick={handleClose}>Cancelar</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
