import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAdditionalUserInfo,
  deleteUser,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from "firebase/auth";
import { setCurrentUser } from "../../store/currentUser";
import { db, auth } from "../../firebase";

import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import Swal from "sweetalert2";

import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  // Heading,
  // Input,
  Link,
  Stack,
  Image,
  Box,
  Center,
  Text,
  FormErrorMessage,
  useColorModeValue,
} from "@chakra-ui/react";
import NavbarRedirect from "../navBarRedirect/NavbarRedirect";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { BiLogoFacebookCircle,BiLogoGoogle } from "react-icons/bi";


import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "../../images/ingreso.jpg";
import logo from "../../assets/logo.png";
import googleIconImageSrc from "../../images/googleLoguin.png";
import facebookIconImageSrc from "../../images/facebookLoguin.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";

const Container = tw(
  ContainerBase
)`min-h-screen bg-logo-300 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButtonGoogle = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white rounded-full`}
  }
  .icon {
    ${tw`w-5`}
  }
  .text {
    ${tw`ml-4`}
  }
`;
const SocialButtonFacebook = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100  hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white rounded-full`}
  }
  .icon {
    ${tw`w-6`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-logo-100 text-gray-100 w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw``}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-logo-125 text-center hidden lg:flex justify-center`;


const Ingreso = () => {
  const logoLinkUrl = "/";
  const illustrationImageSrc = illustration;
  const headingText = "Ingresá en Agencia Volcan";

  const submitButtonText = "Ingresar";
  const SubmitButtonIcon = LoginIcon;
  const forgotPasswordUrl = "#";
  const signupUrl = "#";

  const [formData, setFormData] = useState({ email: "", password: "" });
  const [isAdmin, setIsAdmin] = useState(false);
  const { email, password } = formData;
  const navigate = useNavigate();

  const socialLogInGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((res) => {
        const verifyUser = getAdditionalUserInfo(res);
        var loggedInUser = res.user;

        if (verifyUser?.isNewUser) {
          //delete user if the user is new

          deleteUser(loggedInUser);
          Swal.fire({
            title: "Tu usuario no existe ¿Deseas registrarte?",
            showCancelButton: true,
            confirmButtonText: "Regístrate",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              navigate("/Registro");
              Swal.fire("Ahora puedes crear tu cuenta", "", "info");
            }
          });
        }
        //redirect to home page
        else {
          // window.location = "/";
          onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/auth.user

              const uid = user.uid;
              localStorage.setItem("currentUser", JSON.stringify(uid));

              Swal.fire({
                title: `Bienvenido <br> ${user?.displayName}`,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              });
              navigate("/");
              // ...
            } else {
              // User is signed out
              // ...
            }
          });
        }
      })
      // .then(() => {
      //   navigate("/");
      // })
      .catch((err) => {});
  };

  const socialLogInFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then((res) => {
        const verifyUser = getAdditionalUserInfo(res);

        var loggedInUser = res.user;

        if (verifyUser?.isNewUser) {
          //delete user if the user is new

          deleteUser(loggedInUser);
          Swal.fire({
            title: "Tu usuario no existe ¿Deseas registrarte?",
            showCancelButton: true,
            confirmButtonText: "Regístrate",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              navigate("/Registro");
              Swal.fire("Ahora puedes crear tu cuenta", "", "info");
            }
          });
        } else {
          onAuthStateChanged(auth, (user) => {
            if (user) {
              const uid = user.uid;
              localStorage.setItem("currentUser", JSON.stringify(uid));
              Swal.fire({
                title: `Bienvenido <br> ${user?.displayName}`,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              });
              // ...
            } else {
              // User is signed out
              // ...
            }
          });
        }
      })
      .then(() => {
        navigate("/");
      })
      .catch((err) => {});
  };

  const handleChange = (text) => (e) => {
    setFormData({ ...formData, [text]: e.target.value });
  };
  // const setUserStorage = async (userId) => {
  //   console.log("setUserStorageuser", userId);

  //   try {
  //     console.log("setUserStorageuser", userId);

  //     // const userId = auth.currentUser.uid;
  //     const docRef = doc(db, "user", userId);
  //     const docSnap = await getDoc(docRef);
  //     if (docSnap.exists()) {
  //       console.log("dataaaa", docSnap.data());
  //     } else {
  //       console.log("dataaaa no existe");
  //     }
  //   } catch (error) {
  //     console.log("no labura");
  //   }
  // };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      emailValidate == false &&
      email != null &&
      email != "" &&
      password != null &&
      password != "" &&
      passwordValidate == false
    ) {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          onAuthStateChanged(auth, (user) => {
            if (user) {
              localStorage.setItem("currentUser", JSON.stringify(user?.uid));
              Swal.fire({
                title: `Bienvenido <br> ${user?.displayName}`,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              });
              navigate("/");

              // ...
            } else {
              // User is signed out
              // ...
            }
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.message == "Firebase: Error (auth/user-not-found).") {
            Swal.fire({
              title: "Tu usuario no existe ¿Deseas registrarte?",
              showCancelButton: true,
              confirmButtonText: "Regístrate",
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                navigate("/Registro");
                Swal.fire("Ahora puedes crear tu cuenta", "", "info");
              }
            });
          } else if (err.message == "Firebase: Error (auth/wrong-password).") {
            Swal.fire("Verifica tu correo o contraseña", "", "warning");
          }
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Debes ingresar todos los datos!",
      });
    }
  };

  const [emailValidate, setEmailValidate] = useState(false);
  const [passwordValidate, setPasswordValidate] = useState(false);
  const requeridoPassword = (e) => {
    if (
      password.length > 6 &&
      password != null &&
      password != "" &&
      password.trim() != null
    ) {
      setPasswordValidate(false);
    } else {
      setPasswordValidate(true);
    }
  };

  const requeridoEmail = (e) => {
    let expresion = /\w+@\w+\.[a-z]/;

    if (
      email.length > 2 &&
      email != null &&
      email != "" &&
      email.trim() != null &&
      expresion.test(email)
    ) {
      setEmailValidate(false);
    } else {
      setEmailValidate(true);
    }
  };

  const updatePassword = () => {
    Swal.fire({
      title: "Recuperar contraseña   Ingresa tu correo",
      input: "email",
      showCancelButton: true,
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
    }).then((resultado) => {
      console.log(resultado);
      if (resultado.isConfirmed == false) {
        Swal.fire(`Cancelaste la recuperación`);
      } else if (resultado.value) {
        let email = resultado.value;
        sendPasswordResetEmail(auth, email);
        Swal.fire(`En unos momentos recibirás un correo. Muchas gracias`);
      }
    });
  };

  return (
    <>
      <AnimationRevealPage>
        <Container>
          <Content>
            <MainContainer>
              <LogoLink href={logoLinkUrl}>
                <LogoImage src={logo} />
              </LogoLink>
              <MainContent>
                <Heading>{headingText}</Heading>
                <FormContainer>
                  <SocialButtonsContainer>
                    <SocialButtonGoogle
                      onClick={socialLogInGoogle}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="iconContainer">
                        <img src={googleIconImageSrc} className="icon" alt="" />
                      </span>
                      <span className="text">Ingresar con Google</span>
                    </SocialButtonGoogle>
                    <SocialButtonFacebook
                      onClick={socialLogInFacebook}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="iconContainer">
                        <img
                          src={facebookIconImageSrc}
                          className="icon"
                          alt=""
                        />
                      </span>
                      <span className="text">Ingresar con Facebook</span>
                    </SocialButtonFacebook>
                  </SocialButtonsContainer>
                  <DividerTextContainer>
                    <DividerText>O puedes ingresar con tu e-mail</DividerText>
                  </DividerTextContainer>
                  <Form onSubmit={handleSubmit}>
                    <FormControl id="email" isInvalid={emailValidate}>
                      <FormLabel>Email</FormLabel>
                      <Input
                        type="email"
                        onChange={handleChange("email")}
                        value={email}
                        placeholder="Email"
                        onBlur={requeridoEmail}
                      />
                      {!emailValidate ? (
                        <div></div>
                      ) : (
                        <FormErrorMessage>
                          Ingrese su email por favor.
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl id="password" isInvalid={passwordValidate}>
                      <FormLabel>Contraseña</FormLabel>
                      <Input
                        placeholder="Password"
                        type="password"
                        onChange={handleChange("password")}
                        value={password}
                        onBlur={requeridoPassword}
                      />
                      {!passwordValidate ? (
                        <div></div>
                      ) : (
                        <FormErrorMessage>
                          {" "}
                          Su contraseña debe tener más de 6 caracteres
                        </FormErrorMessage>
                      )}
                    </FormControl>
                     <SubmitButton type="submit">
                      <SubmitButtonIcon className="icon" />
                      <span className="text">{submitButtonText}</span>
                    </SubmitButton>
                  </Form>
                  <p tw="mt-6 text-xs text-gray-600 text-center">
                    <a
                      href={forgotPasswordUrl}
                      tw="border-b border-gray-500 border-dotted"
                    >
                      Olvidaste tu contraseña ?
                    </a>
                  </p>
                  <p tw="mt-8 text-sm text-gray-600 text-center">
                    ¿Todavía no tenes una cuenta?{" "}
                    <a
                      href={signupUrl}
                      tw="border-b border-gray-500 border-dotted"
                      onClick={() => {
                        navigate("/Registro");
                      }}
                    >
                      Crear Una Cuenta
                    </a>
                  </p>
                </FormContainer>
              </MainContent>
            </MainContainer>
            <IllustrationContainer>
              <img src={illustrationImageSrc} />
            </IllustrationContainer>
          </Content>
        </Container>
      </AnimationRevealPage>
    </>
  );
};

export default Ingreso;
//   return (
//     <>
//       <NavbarRedirect></NavbarRedirect>
//       <Box w="100%" h="4em"></Box>
//       <Stack
//         minH={"100vh"}
//         direction={{ base: "column", md: "row" }}
//         bg={useColorModeValue("brand.lightBlue5", "brand.sodarkblue2")}
//       >
//         <Flex p={8} flex={1} align={"center"} justify={"center"}>
//           <Stack
//             spacing={4}
//             w={"full"}
//             maxW={"md"}
//             rounded="10"
//             boxShadow="dark-lg"
//             p={5}
//             bgGradient={useColorModeValue(
//               "linear(to-t, brand.green6, brand.lightBlue5)",
//               "linear(to-t, brand.darkblue3, brand.blue4)"
//             )}
//           >
//             <Heading size="2xl" color={useColorModeValue("", "#000000")}>
//               Ingresa
//             </Heading>
//             <Link
//               onClick={() => {
//                 navigate("/Registro");
//               }}
//               fontWeight={"bold"}
//               color={useColorModeValue("brand.blue4", "brand.green6")}
//             >
//               No tienes cuenta? Regístrate
//             </Link>
//             <form onSubmit={handleSubmit}>
//               <FormControl id="email" isInvalid={emailValidate}>
//                 <FormLabel>Email</FormLabel>
//                 <Input
//                   border="2px solid "
//                   borderColor={useColorModeValue(
//                     "brand.darkblue3",
//                     "brand.green6"
//                   )}
//                   type="email"
//                   onChange={handleChange("email")}
//                   value={email}
//                   onBlur={requeridoEmail}
//                 />
//                 {!emailValidate ? (
//                   <div></div>
//                 ) : (
//                   <FormErrorMessage>
//                     Ingrese su email por favor.
//                   </FormErrorMessage>
//                 )}
//               </FormControl>
//               <FormControl id="password" isInvalid={passwordValidate}>
//                 <FormLabel>Contraseña</FormLabel>
//                 <Input
//                   border="2px solid "
//                   borderColor={useColorModeValue(
//                     "brand.darkblue3",
//                     "brand.green6"
//                   )}
//                   type="password"
//                   onChange={handleChange("password")}
//                   value={password}
//                   onBlur={requeridoPassword}
//                 />
//                 {!passwordValidate ? (
//                   <div></div>
//                 ) : (
//                   <FormErrorMessage>
//                     {" "}
//                     Su contraseña debe tener más de 6 caracteres
//                   </FormErrorMessage>
//                 )}
//               </FormControl>
//               <Stack spacing={6}>
//                 <Stack
//                   direction={{ base: "column", sm: "row" }}
//                   align={"start"}
//                   justify={"space-between"}
//                 >
//                   {/* <Checkbox>Recordarme?</Checkbox> */}
//                   <Link
//                     mt={3}
//                     color={useColorModeValue("brand.blue4", "brand.green6")}
//                     onClick={updatePassword}
//                     fontWeight={"bold"}
//                   >
//                     Olvidate tu contraseña?
//                   </Link>
//                 </Stack>
//                 <Button
//                   type="submit"
//                   variant={"solid"}
//                   colorScheme="blue"
//                   bgGradient="linear(to-b, brand.lightBlue5, brand.blue4, brand.darkblue3)"
//                   color="black"
//                 >
//                   Ingresar
//                 </Button>
//               </Stack>
//             </form>
//             <Center p={8}>
//               <Stack spacing={2} align={"center"} maxW={"md"} w={"full"}>
//                 <Heading fontSize={"2xl"} textAlign={"center"} p={2}>
//                   Ingreso directo
//                 </Heading>
//                 <Button
//                   leftIcon={<FcGoogle />}
//                   onClick={socialLogInGoogle}
//                   borderColor={useColorModeValue("purple.600", "#000000")}
//                   isFullWidth
//                 >
//                   <Center>
//                     <Text>
//                       {` `} Ingreso con Google {` `}
//                     </Text>
//                   </Center>
//                 </Button>
//                 <Button
//                   colorScheme={"facebook"}
//                   leftIcon={<FaFacebook />}
//                   onClick={socialLogInFacebook}
//                   isFullWidth
//                 >
//                   <Center>
//                     <Text>Ingreso con Facebook</Text>
//                   </Center>
//                 </Button>
//               </Stack>
//             </Center>
//           </Stack>
//         </Flex>
//         <Flex flex={1}>
//           <Image
//             alt={"Login Image"}
//             objectFit={"cover"}
//             src={
//               "https://img3.wallspic.com/crops/2/3/9/7/3/137932/137932-patada-la_ciudad_de_manchester_f_c-3840x2160.jpg"
//             }
//           />
//         </Flex>
//       </Stack>
//     </>
//   );
// };

// export default Login;
