import React from "react";
import Navbar from "../../navBar/NavBarLive";
import EventosAdmin from "../eventos/EventosAdmin";
import FirstPage from "../eventos/FirstPage";



const HomeAdmin = () => {
  return (
    <>    
     <FirstPage/>
     </>

  );
};

export default HomeAdmin;

/*

const HomeAdmin = () => {
  return (
    <div>
      <Navbar></Navbar>
      <EventosAdmin></EventosAdmin>

    </div>
  );
};

export default HomeAdmin;

*/
