import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/home/Home.jsx";
import Registro from "./components/registro/Registro";

import Ingreso from "./components/ingreso/Ingreso.jsx";
import { setCurrentUser } from "./store/currentUser";
import { useDispatch, useSelector } from "react-redux";
import Perfil from "./components/perfil/Perfil.jsx";
import AdminHome from "./components/administracion/homeAdmin/HomeAdmin.jsx";
import Live from "./components/secciones/live/Live.jsx"
import Blog from "./components/secciones/blog/Blog.jsx"
import Ligas from "./components/secciones/ligas/Ligas.jsx"

function App() {
  const dispatch = useDispatch();
  const currentUserlocalStorage = JSON.parse(
    localStorage.getItem("currentUser")
  );

  useEffect(() => {
    dispatch(setCurrentUser(currentUserlocalStorage));
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Registro" element={<Registro />} />
      <Route path="/Ingreso" element={<Ingreso />} />
      <Route path="/Perfil" element={<Perfil />} />
      <Route path="/Administracion" element={<AdminHome />} />
      <Route path="/Live" element={<Live/>} />
      <Route path="/Ligas" element={<Ligas/>} />
      <Route path="/Blog" element={<Blog/>} />
    </Routes>
  );
}

export default App;
