import React, { Suspense } from "react";
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store.js";
import { ChakraBaseProvider } from '@chakra-ui/react';
import "./index.css";
import theme from './themes'

ReactDOM.render(
  <ChakraBaseProvider theme={theme}>
    <Suspense fallback={"Conectando la app"}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </Suspense>
  </ChakraBaseProvider>,
  document.getElementById('root')
);